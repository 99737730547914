<template>
  <br />
  <div v-if="skillItems" class="wrapper-skills columns">
    <div>
      <transition-group name="fadeslide" tag="div" appear>
        <div
          v-for="(skillContent, skillIndex) in filteredSkills"
          :key="skillIndex"
        >
          <PopperWrapper
            arrow="true"
            arrowPadding="20px"
            :placement="orientTooltip"
          >
            <div class="skills">
              <div class="skillname">
                <span>
                  {{ skillContent.name }}
                </span>
              </div>
              <div class="skillscore">
                <ScoreBoxes
                  :skill-score="skillContent.score"
                  :skill-discipline="skillContent.discipline"
                />
              </div>
            </div>
            <template #content>
              <div class="sentence_case">
                [{{ skillDisciplineMap[skillContent.discipline] }}].[{{
                  skillContent.name
                }}]
              </div>
              <br />
              <div class="fulltext">{{ skillContent.description }}</div>
            </template>
          </PopperWrapper>
        </div>
      </transition-group>
    </div>
  </div>
  <div v-else>
    <Loading :messageWhenNoItems="messageWhenNoItems"></Loading>
  </div>
  <br />
  <div class="columns">
    <div v-if="skillsCategories" class="disciplines keeptogether">
      <h5>Diciplines</h5>
      <div class="disciplinePicker">
        <div
          class="form-check form-check-inline disciplinePicker-item"
          v-for="categoryContent in skillsCategories"
          :key="categoryContent.id"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="checkedCategories"
            v-bind:value="categoryContent.id"
          />
          <label class="form-check-label">
            {{ categoryContent.name }}
          </label>
        </div>
      </div>
    </div>
    <div v-else>
      <Loading :messageWhenNoItems="messageWhenNoItems"></Loading>
    </div>
    <div></div>
    <div class="legend keeptogether">
      <h5>Legend</h5>
      <div v-for="(legendContent, legendIndex) in legend" :key="legendIndex">
        <div class="skills">
          <div class="skillname">
            <span>{{ legendContent.name }}</span>
          </div>
          <div class="skillscore">
            <ScoreBoxes
              :skill-score="legendContent.score"
              :skill-discipline="legendContent.discipline"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ScoreBoxes from './ScoreBoxes.vue'
import PopperWrapper from '@/components/common/PopperWrapper.vue'
import Loading from '@/components/common/Loading.vue'

export default {
  name: 'Scores',
  data() {
    return {
      checkedCategories: [],
      windowWidth: window.innerWidth,
      legend: [
        { name: 'Awareness', score: 1, discipline: 0 },
        { name: 'Competent', score: 2, discipline: 0 },
        { name: 'Proficient', score: 3, discipline: 0 },
        { name: 'Expert', score: 4, discipline: 0 },
        { name: 'Pushing Possibilities', score: 5, discipline: 0 },
      ],
    }
  },
  components: {
    ScoreBoxes,
    PopperWrapper,
    Loading,
  },
  props: {
    skillsCategories: {
      type: Array,
      default: null,
    },
    picked: {
      type: Boolean,
      default: null,
    },
    skillItems: null,
    messageWhenNoItems: {
      type: String,
      default: 'Loading scores...',
    },
  },
  computed: {
    filteredSkills() {
      if (!this.checkedCategories.length) return this.skillItems
      return this.skillItems.filter((skill) =>
        this.checkedCategories.includes(skill.discipline),
      )
    },
    orientTooltip() {
      if (this.windowWidth < 900) return 'bottom'
      return 'right'
    },
    skillDisciplineMap() {
      const skillDisciplineMap = {}
      this.skillsCategories.forEach((discipline) => {
        skillDisciplineMap[discipline.id] = discipline.name
      })
      return skillDisciplineMap
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
}
</script>
<style scoped lang="scss">
/*CSS3 multiple columns.*/
/* Get em size for colums: 184/16 */
.columns {
  -moz-column-width: 18em; /* Firefox */
  -webkit-column-width: 18em; /* webkit, Safari, Chrome */
  column-width: 18em;
}
/*remove standard list and bullet formatting from ul*/
.columns ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
/* correct webkit/chrome uneven margin on the first column*/
.columns ul li:first-child {
  margin-top: 0px;
}
.skillname {
  width: 10em;
  overflow: hidden;
  white-space: nowrap;
  float: left;
  margin-right: 10px;
  text-transform: none;
}
.skillscore {
  width: 8em;
}
.skills {
  transition: all 1000ms ease-in;
  display: flex;
  border-radius: 3px;
}
.skills:hover {
  background: lightgrey;
}

.fadeslide-enter-active {
  animation: fadeslide-in 1s;
}

.fadeslide-leave-active {
  animation: fadeslide-in 1s reverse;
}

@keyframes fadeslide-in {
  from {
    opacity: 0;
    transform: translateX(10px);
  }
  to {
    opacity: 1;
  }
}

.disciplinePicker {
  overflow: hidden;
}

.disciplinePicker-item {
  float: left;
  clear: left;
}

:deep(.popper) {
  width: 75vw;
  @media screen and (min-width: 1024px) {
    width: 20vw;
  }
}

.fulltext {
  white-space: pre-wrap;
}

div.columns {
  column-width: 300px;
  -moz-column-width: 300px;
  -webkit-column-width: 300px;
}

div.keeptogether {
  display: inline-block;
  width: 100%;
}
</style>
