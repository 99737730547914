<template>
  <Popper v-bind="$attrs" hover :interactive="false">
    <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </Popper>
</template>

<script>
import { defineComponent } from 'vue'
import Popper from 'vue3-popper'

export default defineComponent({
  name: 'PopperWrapper',
  components: {
    Popper,
  },
})
</script>
