<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    v-for="n in skillScore"
    class="checked"
    :class="['colour_' + skillDiscipline]"
    v-bind:key="n"
  >
    <path
      d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z"
      :fill="disciplineColour(skillDiscipline)"
    />
  </svg>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    v-for="n in missingScore"
    class="checked"
    :class="['colour_' + skillDiscipline]"
    v-bind:key="n"
  >
    <path
      d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z"
      :fill="disciplineColour(skillDiscipline)"
    />
  </svg>
</template>
<script>
import mdiCheckboxBlank from '@/assets/material-design-icons/checkbox-blank.svg'
import mdiCheckboxBlankOutline from '@/assets/material-design-icons/checkbox-blank-outline.svg'

export default {
  name: 'ScoreBoxes',
  setup() {
    return {
      mdiCheckboxBlank,
      mdiCheckboxBlankOutline,
    }
  },
  components: {},
  props: {
    skillScore: {
      type: Number,
    },
    skillDiscipline: {
      type: Number,
    },
    totalScore: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    missingScore() {
      return this.totalScore - this.skillScore
    },
  },
  methods: {
    disciplineColour(discipline) {
      if (discipline === 1) return '#000000'
      if (discipline === 2) return '#383838'
      if (discipline === 3) return '#505050'
      if (discipline === 4) return '#787878'
      if (discipline === 5) return '#A9A9A9'
      if (discipline === 6) return '#C8C8C8'
      if (discipline === 0) return '#A9A9A9'
      return 'black'
    },
  },
}
</script>
<style></style>
