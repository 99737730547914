import api from './axiosConfig.js'

export default {
  getAll() {
    return api({
      method: 'get',
      url: '/disciplines/',
    })
  },
}
