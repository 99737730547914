<template>
  <h1 class="topCenter">Skills</h1>
  <div class="wrapper overflow-auto">
    <Scores
      :skill-items="skills"
      :message-when-no-items="messageWhenNoItems"
      :skills-categories="disciplines"
    />
  </div>
</template>
<script>
import Scores from '@/components/scores/Scores.vue'
import SkillsAPI from '@/api/skills'
import DisciplinesAPI from '@/api/disciplines'

export default {
  name: 'Skills',
  components: {
    Scores,
  },
  data() {
    return {
      disciplines: [],
      skills: [],
      messageWhenNoItems: 'Loading skills...',
    }
  },
  created() {
    SkillsAPI.getAll()
      .then((response) => (this.skills = response.data))
      .catch((error) => console.log(error))
    DisciplinesAPI.getAll()
      .then((response) => (this.disciplines = response.data))
      .catch((error) => console.log(error))
  },
}
</script>
<style scoped lang="scss">
.topCenter {
  margin: 0;
}
</style>
